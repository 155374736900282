
import { ApiService } from 'spc/shared/api/api.service';
import { set, get } from 'lodash';

export class GoogleLocationService {
  constructor (private googleLocationModal, private $api: ApiService, private unwrapError) {
    'ngInject';
  }

  setGoogleLocation = (lead) => {
    const locationData = { ...get(lead, 'request.locationDetails'), address: get(lead, 'request.location') };
    return this.googleLocationModal({ locationData })
      .then((res) => {
        if (!get(res.value, 'location')) {
          return;
        }
        const { address, ...locationDetails } = get(res.value, 'location');
        set(lead, 'request.location', address);
        set(lead, 'request.locationDetails', locationDetails);
        return this.$api.Leads.update({ lead: lead });
      })
      .catch(error => this.unwrapError(error));
  }
}
