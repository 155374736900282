// External Dependencies
import commafy from 'commafy';
import moment from 'moment';

import get from 'lodash/get';
import set from 'lodash/set';
import unset from 'lodash/unset';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import ENUMS from 'common/dist/enums';
import time from 'common/dist/time';

// Sixplus Types
import { ConciergeService } from './concierge.service';
import AnalyticsService from '../../shared/analytics/analytics.service';
import { SpPardotService } from 'spc/shared/sp-pardot/sp-pardot.service';
import { RawBaseUser } from '../../../../database/types/base-user';
import { DLead, LeadRequest } from 'spc/lib/database/types/lead';
import { UserService } from 'spc/services/user.service';
import { ConversationMessagesTableComponent } from '../events-dashboard/conversation-messages-table.component';
import { CityTzMapperService } from '../../shared/cityTZmapper.service';
import { perGuestBudgetsLondon } from '../../../../database/constants/londonConstants';

let ORDERED_LANDING_PAGE_CITIES = [...ENUMS.acceptableUserCities.filter(location => location.premiumCity === false), ...ENUMS.acceptableUserCities.filter(location => location.premiumCity === true)];

// Constants
const DEFAULT_LOCATION = ENUMS.concierge.defaultCity;

const SECTION_NAMES = ['EVENT_OVERVIEW', 'DATE_SELECTION', 'CLIENT_DETAILS', 'OPTIONAL'];

const BUDGET_TYPES = ['Budget Per Person', 'Total Budget'];

const BUDGET_PATH_MAPPER = {
  'Budget Per Person': 'request.budgetPerGuest',
  'Total Budget': 'request.totalEventBudgetDollars'
};

const LOCATION_PATH_OPTIONS = ['location', 'neighborhoods'];

const LOCATION_PATH_MAPPER = {
  neighborhoods: 'request.neighborhoods',
  location: 'request.location'
};

const TIMES = function generate_series() {
  const step = 15;
  const dt = new Date(1970, 0, 1, 6, 0, 0, 0);
  const rc = [];
  while (dt.getDate() === 1) {
    rc.push(dt.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }));
    dt.setMinutes(dt.getMinutes() + step);
  }
  return rc;
}();

class ConciergeFormComponent {
  user: any;
  trackEvent: ({ lead, client }: { lead: any, client: any }) => any;
  viewReady: () => any;
  lead: LeadRequest = {};
  client: RawBaseUser;
  budgetChoice: string = BUDGET_TYPES[1];
  budgetPath: string = BUDGET_PATH_MAPPER[this.budgetChoice];
  display: { cuisineMessage?: string, locationMessage?: string, time?: string, phoneNumber: string, privacyMessage?: string } = { phoneNumber: this.ENUMS.cityNumbers[DEFAULT_LOCATION] };
  locationChoice: string = LOCATION_PATH_OPTIONS[0];
  locationPath: string = LOCATION_PATH_MAPPER[this.locationChoice];
  currentSection: string = SECTION_NAMES[0];
  sectionNames: string[] = SECTION_NAMES;
  errors: { lead?: any, client?: any } = { lead: {}, client: {} };
  conciergeForm: any;
  modalOpen: boolean = false;
  location: string = DEFAULT_LOCATION;
  neighborhoodsMap: { [area: string]: { [city: string]: string[] } };
  ui: { canSubmit: boolean, step: number, submitErrorMessage: string } = { canSubmit: true, step: 1, submitErrorMessage: '' };
  isPremiumMember: boolean;
  selectedCity: string;
  endTime;
  displayOptionalSection: boolean = false;
  isBudgetFieldDisabled: boolean = true;
  isEndTimeFieldDisabled: boolean = true;
  defaultStartTime: number = 1800;
  MINIMUM_LEAD_BUDGET = 1000;

  constructor(
    private $analytics: AnalyticsService,
    private $api,
    private unwrapError,
    private ENUMS,
    private selectionModal,
    private neighborhoodModal,
    private conciergeService: ConciergeService,
    private $scrollService,
    private $timeout,
    private $cloudinary,
    private $user: UserService,
    private spPardot: SpPardotService,
    private cityTzMapperService: CityTzMapperService,
    private $location,
    private $scope,
    private googleLocationModal
  ) {
    'ngInject';
  }

  $onChanges = (data) => {
    if (data.user && this.user) {
      this.setClient();
    }
  }

  $onInit() {
    this.viewReady();
    this._trackPageView();
    this.$user.isPremiumMember()
      .then((res) => {
        this.isPremiumMember = res;
        if (this.isPremiumMember) {
          ORDERED_LANDING_PAGE_CITIES = ENUMS.acceptableUserCities;
        }
      });
    return this.$api.Landing.getLocation()
      .then((data) => {
        const location = data.location;
        this.neighborhoodsMap = data.neighborhoodsMap;
        this.display.phoneNumber = this.ENUMS.cityNumbers[location] || this.ENUMS.cityNumbers[DEFAULT_LOCATION];
      })
      .catch(error => this.unwrapError(error));
  }

  /**
   * Tracks page view on page load, and handles any conversion-related logic
   */

  _trackPageView = (): void => {
    const referrer = this.$analytics.createReferrerData();
    const eventName = get(this.ENUMS, 'analyticsEvents.visited.custom.concierge');
    const eventParams = {
      referrerService: referrer.service,
      referrerUrl: referrer.url
    };
    const eventAction = 'track page';
    this.$analytics.$trackEvent(eventName, eventParams, eventAction);
  }

  backButton = () => '<';

  openSelectionModal = (choices, path, options, fieldId) => {
    return this.selectionModal({ choices, currentChoice: get(this.lead, path), options })
      .then((response) => {
        const choice = get(response, 'value.choice');
        if (choice) {
          set(this.lead, path, choice);
          document.getElementById(fieldId).blur();
        }
      })
      .catch(error => this.unwrapError(error));
  }

  selectStep = (selected) => {
    this.ui.step = selected;
  }

  openBudgetPerGuestModal = () => {
    if (this.isBudgetFieldDisabled) {
      return;
    }
    const budgetPerGuestChoices = get(this.lead, 'request.city') === 'London' ? perGuestBudgetsLondon : this.ENUMS.concierge.perGuestBudgets;
    return this.openSelectionModal(budgetPerGuestChoices, this.budgetPath, {}, 'budget-per-person');
  }

  setGoogleLocation = (event) => {
    event.stopPropagation();
    const locationData = { ...get(this.lead, 'request.locationDetails'), address: get(this.lead, 'request.location') };
    return this.googleLocationModal({ locationData })
      .then((res) => {
        const { address, ...locationDetails } = get(res.value, 'location');
        set(this.lead, 'request.location', address);
        set(this.lead, 'request.locationDetails', locationDetails);
      })
      .catch((error) => {
        this.unwrapError(error);
      });
  }

  openMealTypeModal = () => {
    return this.openSelectionModal(this.ENUMS.concierge.mealTypes, 'request.mealType', {}, 'meal-type');
  }

  selectAVOption = () => {
    const path = 'request.AV.selected';
    const choices = ['will be', 'will not be'];
    const valueMapper = { 'will be': true, 'will not be': false };
    return this.selectionModal({ choices, currentChoice: get(this.lead, path) })
      .then((response) => {
        const choice = get<string>(response, 'value.choice', '');
        if (!choice) {
          return;
        }
        const value = valueMapper[choice];
        set(this.lead, path, value);
      })
      .catch(error => this.unwrapError(error));
  }

  selectOccasion = (): Promise<void> => {
    const options = { custom: false, search: false, name: 'occasion' };
    const path = 'request.occasion';
    const alternatePath = 'request.customOccasion';
    const choices = this.ENUMS.eventType
      .filter(type => type.visible)
      .map(type => type.label);
    return this.selectionModal({ choices, currentChoice: get(this.lead, path) || get(this.lead, alternatePath), options })
      .then((response) => {
        const choice = get(response, 'value.choice');
        if (!choice) {
          return;
        }
        if (this.ENUMS.concierge.occasions.indexOf(choice) > -1 && choice !== 'Other') {
          set(this.lead, path, choice);
        } else if (choice === 'Other') {
          set(this.lead, path, choice);
          set(this.lead, alternatePath, choice);
        } else {
          set(this.lead, path, 'Other');
          set(this.lead, alternatePath, choice);
        }
        document.getElementById('occasion').blur();
      }).catch(error => this.unwrapError(error));
  }

  selectBudgetType = () => {
    return this.selectionModal({ choices: BUDGET_TYPES, currentChoice: this.budgetChoice })
      .then((response) => {
        const choice = get(response, 'value.choice');
        if (choice) {
          this.budgetChoice = choice;
          this.budgetPath = BUDGET_PATH_MAPPER[this.budgetChoice];
        }
      })
      .catch(error => this.unwrapError(error));
  }

  toggleIsDateFlexible = () => {
    if (!get(this, 'lead.request.isDateFlexible')) {
      set(this, 'lead.request.isDateFlexible', false);
    }
    this.lead.request.isDateFlexible = !this.lead.request.isDateFlexible;
  }

  toggleDisplayOptionalSection = () => {
    this.displayOptionalSection = !this.displayOptionalSection;
  }

  selectEventStyle = () => {
    const path = `request.eventStyle`;
    const choices = this.ENUMS.concierge.eventStyles;
    return this.selectionModal({ choices, currentChoice: get(this.lead, path) })
      .then((response) => {
        const selectedChoice = get<string[]>(response, 'value.choice');
        if (selectedChoice) {
          set(this.lead, path, selectedChoice);
          document.getElementById('event-style').blur();
        }
      })
      .catch(error => this.unwrapError(error));
  }

  selectCuisine = (event) => {
    event.stopPropagation();
    const options = {
      multi: true,
      search: true,
      custom: true,
      name: 'cuisine'
    };

    const path = `request.preferredCuisineType`;
    const choices = this.ENUMS.cuisineTypes.filter(cuisine => !['Other', 'Afghan', 'African', 'Burmese', 'Game'].includes(cuisine));

    return this.selectionModal({ choices, options, currentChoice: get(this.lead, path) })
      .then((response) => {
        const selectedChoices = get<string[]>(response, 'value.currentChoices');
        if (selectedChoices) {
          set(this.lead, path, selectedChoices);
          this.display.cuisineMessage = this.createMessage(selectedChoices);
        }
      })
      .catch(error => this.unwrapError(error));
  }

  setDate = (date) => {
    set(this.lead, 'request.date', date);
    this.resetFieldError('request.date');
  }

  selectStartTime = () => {
    const path = `request.time`;
    return this.selectionModal({ choices: TIMES, currentChoice: this.display.time || moment(this.defaultStartTime, 'Hmm').format('h:mm A') })
      .then((response) => {
        const choice = get(response, 'value.choice');
        if (choice) {
          this.display.time = choice;
          set(this.lead, path, moment(choice, 'h:mm A').format('Hmm'));
          this.isEndTimeFieldDisabled = false;
          document.getElementById('time').blur();
        }
      }).catch(error => this.unwrapError(error));
  }

  selectEndTime = () => {
    if (this.isEndTimeFieldDisabled) {
      return;
    }
    const endTimeOptions = time.getTimeSelectOptions(15)
        .filter(time => time.value > get(this.lead, 'request.time'));
    return this.selectionModal({ choices: endTimeOptions.map(time => time.display), currentChoice: this.display.time })
      .then((response) => {
        const choice = get(response, 'value.choice');
        if (choice) {
          this.endTime = choice;
          set(this.lead, 'request.duration', this.calculateEventDuration());
          document.getElementById('duration').blur();
        }
      }).catch(error => this.unwrapError(error));
  }

  selectCity = () => {
    const path = `request.city`;
    const options = { custom: true, search: true, city: true, isPremiumMember : this.isPremiumMember, name: 'city' };
    const cityChoices = ORDERED_LANDING_PAGE_CITIES.map(city => city.name);

    return this.selectionModal({ choices: cityChoices , currentChoice: get(this.lead, path), options })
      .then((response) => {
        let choice = get(response, 'value.choice');
        if (choice) {
          this.isBudgetFieldDisabled = false;
          this.selectedCity = choice;
          choice = get(ORDERED_LANDING_PAGE_CITIES.find(city => city.name === choice), 'value') || choice;
          this.handleCitySelection({ choice, path });
          document.getElementById('city').blur();
        }
      })
      .catch(error => this.unwrapError(error));
  }

  handleCitySelection = ({ choice, path }) => {
    set(this.lead, path, choice);
    this.display.phoneNumber = this.ENUMS.cityNumbers[choice] || this.ENUMS.cityNumbers[DEFAULT_LOCATION];
    set(this.lead, this.locationPath, null);
    this.display.locationMessage = null;
  }

  allowLocationPathEdit = () => {
    const city = get(this.lead, 'request.city');
    return this.ENUMS.concierge.conciergeFormCities.indexOf(city) > -1;
  }

  selectLocationPath = () => {
    if (!this.allowLocationPathEdit()) {
      return;
    }
    return this.selectionModal({ choices: LOCATION_PATH_OPTIONS, currentChoice: this.locationPath })
      .then((response) => {
        const choice = get(response, 'value.choice');
        if (!choice) {
          return;
        }
        this.locationChoice = choice;
        this.locationPath = LOCATION_PATH_MAPPER[this.locationChoice];
      })
      .catch(error => this.unwrapError(error));
  }

  selectNeighborhoods = () => {
    return this.neighborhoodModal({
      selectedArea: get(this.lead, 'request.city'),
      currentChoices: get(this.lead, this.locationPath),
      neighborhoodsMap: this.neighborhoodsMap
    })
    .then((response) => {
      const currentChoices = get(response, 'value.currentChoices');
      if (currentChoices) {
        set(this.lead, this.locationPath, currentChoices);
        this.display.locationMessage = this.createMessage(currentChoices);
      }
    })
    .catch(error => this.unwrapError(error));
  }

  selectPrivacy = () => {
    const PRIVACY_PATH = 'request.privacy';
    const options = {
      multi: true,
      name: 'privacy'
    };
    const currentChoice = get(this.lead, PRIVACY_PATH);
    return this.selectionModal({ choices: this.ENUMS.concierge.privacyTypes, options, currentChoice })
      .then((response) => {
        const currentChoices = get<string[]>(response, 'value.currentChoices');
        if (currentChoices) {
          set(this.lead, PRIVACY_PATH, currentChoices);
          document.getElementById('privacy').blur();
          this.display.privacyMessage = this.createMessage(currentChoices);
        }
      })
      .catch(error => this.unwrapError(error));
  }

  openAVOptionsModal = () => {
    const path = 'request.AV.options';
    const options = { multi: true, search: true, custom: true, name: 'A/V' };
    return this.selectionModal({ choices: this.ENUMS.concierge.avOptions, options, currentChoice: get(this.lead, path) })
      .then((response) => {
        const choices = get<string[]>(response, 'value.currentChoices');
        if (choices) {
          set(this.lead, path, choices);
        }
      })
      .catch(error => this.unwrapError(error));
  }

  getColumnCities = (column: number, totalColumns: number) => {
    const cityEachColumnLength = Math.ceil(this.ENUMS.acceptableUserCities.length / totalColumns);
    return this.ENUMS.acceptableUserCities.slice((column - 1) * cityEachColumnLength, column * cityEachColumnLength);
  }

  setClient = () => {
    if (!this.user) {
      return;
    }
    this.client = cloneDeep(this.user);
  }

  calculateEventDuration = () => {
    const eventStartTime = get(this.lead, 'request.time');
    const eventEndTime = moment(this.endTime, 'h:mm A').format('Hmm');
    return time.getDurationFromTimes(eventStartTime, eventEndTime);
  }

  checkRequiredFields = () => {
    if (!get(this.lead, 'name')) {
      set(this.errors, 'lead.name', 'Invalid Data');
    }

    if (!get(this.lead, 'request.eventStyle')) {
      set(this.errors, 'lead["request.eventStyle"]', 'Invalid Data');
    }

    if (this.budgetChoice === 'Budget Per Person' && !get(this.lead, 'request.budgetPerGuest')) {
      set(this.errors, 'lead["request.budgetPerGuest"]', 'Invalid Data');
    }
  }

  submitLead = async () => {
    this.ui.canSubmit = false;
    this.errors = {};
    set(this.client, 'internal.referrer', this.$analytics.getReferrerData());

    if (get(this.lead, 'request.date')) {
      const tzMappedDate = this.cityTzMapperService.getCityTZmappedDate({ city: get(this.lead, 'request.city'), date: moment(get(this.lead, 'request.date')).format('YYYY-MM-DD') });
      set(this.lead, 'request.date', tzMappedDate ? tzMappedDate : get(this.lead, 'request.date'));
    }

    set(this.lead, 'request.AV.selected', get(this.lead, 'request.AV.information') ? !!this.lead.request.AV.information.trim() : false);
    if (get(this.client, 'profile.email')) {
      this.client.profile.email = this.client.profile.email.trim().toLowerCase();
    }
    try {
      const isleadDataValid = await this.validateLead();
      if (!isleadDataValid) {
        return;
      }

      await this.$api.Leads.create({ client: this.client, lead: this.lead, sendEmail: true });
      this.$analytics.clearReferrerData();
      this.trackEvent({ lead: this.lead, client: this.client });
      this.conciergeService.setLeadAndClient({ lead: this.lead, client: this.client });
      this.redirect('/concierge-submitted');
    } catch (error) {
      this.ui.canSubmit = true;
      this.unwrapError(error);
    }
  }

  redirect = (url) => {
    this.$location.path(url);
    this.$scope.$apply();
  }

  createMessage = (choices: string[]): string => {
    return choices.length < 3 ? choices.join(' and ') : `${choices[0]}, ${choices[1]}, and ${choices.length - 2} other` + ((choices.length - 2 > 1) ? 's' : '');
  }

  resetFieldError = (path) => {
    if (!get(this.lead, path) || !get(this.errors.lead, path)) {
      return;
    }
    if (path === 'request.totalEventBudgetDollars' && get(this.lead, path) < this.MINIMUM_LEAD_BUDGET) {
      return;
    }
    delete this.errors.lead[path];
  }

  validateLead = () => {
    this.checkRequiredFields();
    return this.$api.Leads.validate({ lead: this.lead, client: this.client || {} })
      .then((res) => {
        if (this.isLeadInvalid(get(res, 'errors'))) {
          this.errors.lead = { ...this.errors.lead, ...res.errors.lead };
          this.errors.client = get(res.errors, 'client');
          this.ui.submitErrorMessage = '* Please check that all required fields are have been filled out.';
          this.ui.canSubmit = true;
          return false;
        }
        this.ui.submitErrorMessage = '';
        return true;
      })
    .catch(error => this.unwrapError(error));
  }

  isLeadInvalid(errors) {
    if (( errors && (!isEmpty(errors.lead) || !isEmpty(errors.client)) ) || !isEmpty(get(this.errors, 'lead')) ) {
      return true;
    }
    return false;
  }

  setClientDetails = (client) => {
    this.client = { ...this.client, ...client };
  }

  handleKeyDownOnModalField = ($event, modalFunc) => {
    if (this.modalOpen) {
      return;
    }
    if ($event.keyCode === 13 || $event.keyCode === 39 || $event.keyCode === 40) {
      this.modalOpen = true;
      return modalFunc()
        .then(() => this.modalOpen = false);
    }
  }

  unsetField = (event, field) => {
    event.stopPropagation();
    if (field === 'budgetPerGuest') {
      set(this.lead, 'request.budgetPerGuest', '');
    }
    if (field === 'location') {
      set(this.lead, 'request.location', '');
      set(this.lead, 'request.locationDetails', {});
    }
  }

  openDatePicker = () => {
    document.getElementById('event-date').click();
  }

  handleEventPropagation = (event) => {
    event.stopPropagation();
  }
}

export const ConciergeForm = {
  template: require('./concierge-form.component.jade'),
  controller: ConciergeFormComponent,
  bindings: {
    trackEvent: '&',
    viewReady: '&',
    user: '<'
  }
};
