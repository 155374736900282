// External Deps
import * as angular from 'angular';

// Our Deps
import { TopLevelRecosComponent } from './top-level-recos.component';
import { ClientRecosComponent } from './recos.component';
import { DownloadRecosComponent } from './download-recos.component';
import { RecosService } from './recos.service';
import { RecoRequestComponent } from 'spc/recos/reco-request/reco-request.component';
import recoRequestModal from 'spc/recos/reco-request/reco-request.modal';
import eventWithLiteModal from 'spc/recos/reco-request/event-with-lite.modal';
import { ClientAddVenuesComponent } from 'spc/recos/client-add-venues/client-add-venues.component';
import clientAddVenuesModal from 'spc/recos/client-add-venues/client-add-venues.modal';
import recoFeedbackModal from './reco-feedback/reco-feedback.modal';
import { RecoFeedbackComponent } from './reco-feedback/reco-feedback.component';
import { MenuDisplayService } from './menu-display.service';
import { RecoTransferComponent } from './reco-transfer/reco-transfer.component';
import { RecoTransferService } from './reco-transfer/reco-transfer.service';
import recoTransferModal from './reco-transfer/reco-transfer.modal';
import requestConfirmationModal from './reco-request/request-confirmation.modal';
import { RecoRequestService } from './reco-request/reco-request.service';
import { RecosMapService } from './recos-map.service';
import recosMapComponent from './recos-map.component';
import sendProposalModal from './send-proposal/send-proposal.modal';
import addSpaceForShellModal from './add-space-for-shell/add-space-for-shell.modal';
import { AddSpaceForShellComponent } from './add-space-for-shell/add-space-for-shell.component';
import proposalRequestAccess from './reco-request-access/proposal-request-access.modal';
import leadPermissionsModal from './lead-permissions/leadPermissions.modal';
import { LeadPermissionsComponent } from './lead-permissions/leadPermissions.component';
import manageCollaboratorsModal from './manage-collaborators/manage-collaborators.modal';
import { ManageCollaboratorsComponent } from './manage-collaborators/manage-collaborators.component';
import AddCollaboratorsModal from './add-collaborators/add-collaborators.modal';
import { AddCollaboratorsComponent } from './add-collaborators/add-collaborators.component';
import missingEventDetailModal from './venue-edit-event-field-missing/venue-edit-event-field-missing.modal';
import recosShareModal from './recos-share/recos-share.modal';
import { RecoRequestAuthComponent } from './reco-request/reco-request-auth.component';


export const RecosModule = angular.module('RecosModule', [])
  .component('topLevelRecos', TopLevelRecosComponent)
  .component('clientRecos', ClientRecosComponent)
  .component('downloadRecos', DownloadRecosComponent)
  .component('recoRequest', RecoRequestComponent)
  .component('recoFeedback', RecoFeedbackComponent)
  .component('recoTransfer', RecoTransferComponent)
  .component('recosMap', recosMapComponent)
  .component('recoRequestAuth', RecoRequestAuthComponent)
  .component('leadPermissions', LeadPermissionsComponent)
  .component('clientAddVenues', ClientAddVenuesComponent)
  .component('manageCollaborators', ManageCollaboratorsComponent)
  .component('addCollaborators', AddCollaboratorsComponent)
  .component('addSpaceForShell', AddSpaceForShellComponent)
  .service('addSpaceForShellModal', addSpaceForShellModal)
  .service('recoRequestService', RecoRequestService)
  .service('recoTransferService', RecoTransferService)
  .service('recoTransferModal', recoTransferModal)
  .service('recosService', RecosService)
  .service('recoRequestModal', recoRequestModal)
  .service('recosShareModal', recosShareModal)
  .service('eventWithLiteModal', eventWithLiteModal)
  .service('clientAddVenuesModal', clientAddVenuesModal)
  .service('recoFeedbackModal', recoFeedbackModal)
  .service('requestConfirmationModal', requestConfirmationModal)
  .service('menuDisplayService', MenuDisplayService)
  .service('recosMapService', RecosMapService)
  .service('proposalRequestAccess', proposalRequestAccess)
  .service('sendProposalModal', sendProposalModal)
  .service('leadPermissionsModal', leadPermissionsModal)
  .service('manageCollaboratorsModal', manageCollaboratorsModal)
  .service('addCollaboratorsModal', AddCollaboratorsModal)
  .service('missingEventDetailModal', missingEventDetailModal)
  .name;
