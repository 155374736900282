import { ApiService } from 'spc/shared/api/api.service';

class LeadFilterSidebarController {
  search: {
    city?: string,
    currentStatuses?: string[],
    text?: string,
    sort?: any;
    dueDate?: { from?: Date; to?: Date };
    eventDate?: { from?: Date; to?: Date };
    occasions: string[];
    origin?: string;
  };
  ui: { showOccasionMultiselect: boolean; showStatusMultiselect: boolean; } = {
    showStatusMultiselect: false,
    showOccasionMultiselect: false
  };
  cities = this.ENUMS.acceptableUserCities;
  statuses: string[] = this.ENUMS.leadStatuses;
  conciergeTeam: any[];
  originList: string[] = this.ENUMS.concierge.leadCreatedThrough;
  searchLeads: () => any;

  constructor(private ENUMS, private $api: ApiService, private unwrapError) {
    'ngInject';
  }

  startSearch = () => {
    this.searchLeads()
      .catch(error => this.unwrapError(error));
  }

  hideStatusMultiSelect = (selectedOptions) => {
    this.search.currentStatuses = selectedOptions;
    this.ui.showStatusMultiselect = false;
  }

  toggleStatusMultiselectDisplay = () => {
    this.ui.showStatusMultiselect = !this.ui.showStatusMultiselect;
  }

  toggleOccasionMultiselect = () => {
    this.ui.showOccasionMultiselect = !this.ui.showOccasionMultiselect;
  }

  hideOccasionMultiselect = (selectedOptions) => {
    this.search.occasions = selectedOptions;
    this.ui.showOccasionMultiselect = false;
  }

}

export const LeadFilterSidebarComponent = {
  template: require('./lead-filter-sidebar.component.jade'),
  controller: LeadFilterSidebarController,
  bindings: {
    searchLeads: '&',
    search: '<',
    toggleDisplay: '&',
    conciergeTeam: '<'
  }
};
