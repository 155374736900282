import { every, get, set } from 'lodash';
import AnalyticsService from 'spc/shared/analytics/analytics.service';
import { ApiService } from 'spc/shared/api/api.service';

class RecoRequestAuthController {
  message: string;
  authTab?: 'LOG IN' | 'REGISTER' | 'CHECK EMAIL' = 'CHECK EMAIL';
  auth: {
    isEmailValid?: boolean;
    clientEmail?: string;
    isBaseUser?: boolean;
    user?: any
    password?: string;
    errorMessage: string;
    showPassword: boolean
  } = {
    errorMessage: '',
    showPassword: false
  };
  setClient: ({ client, isBaseUser }) => void;
  onAuthSubmit: (submitDetails) => void;
  venueName: string;
  constructor(
    private $api: ApiService,
    private $user,
    private unwrapError,
    private $analytics: AnalyticsService,
  ) {
    'ngInject';
  }

  checkEmail = () => {
    this.auth.errorMessage = '';
    return this.$api.Auth.verifyEmail(this.auth.clientEmail)
      .then((res) => {
        this.auth.isEmailValid = get(res, 'data.valid');
        return this.findUser();
      })
      .then(() => {
        if (get(this.auth, 'user')) {
          this.authTab = this.auth.isBaseUser ? 'REGISTER' : 'LOG IN';
        } else {
          // If no user profile, initialize a new profile with the email and set Referrer data
          set(this.auth, 'user.profile.email', this.auth.clientEmail);
          set(this.auth, 'user.internal.referrer', this.$analytics.getReferrerData());
          this.authTab = 'REGISTER';
        }

        this.setClient({ client: this.auth.user, isBaseUser: this.auth.isBaseUser });
      })
      .catch((error) => {
        this.auth.isEmailValid = get(error, 'data.valid');
        if (!this.auth.isEmailValid) {
          this.auth.errorMessage = `The email is invalid. Please check to make sure there's no typo.`;
        }
        this.unwrapError(error);
      });
  }

  changeAuthTab = () => {
    this.authTab = 'CHECK EMAIL';
  }

  findUser = () => {
    return this.$api.Auth.findUser(this.auth.clientEmail)
      .then((userData) => {
        this.auth = { ...this.auth, ...userData };
      })
      .catch(error => this.unwrapError(error));
  }

  loginWithPassword = () => {
    this.auth.errorMessage = '';
    return this.$user.passwordLogin( this.auth.clientEmail, this.auth.password )
      .catch((error) => {
        this.auth.errorMessage = error.data.error;
        this.unwrapError(error);
      });
  }

  checkRequiredFields = (isBaseUser) => {
    const commonFields = [
      get(this.auth, 'user.profile.name.first'),
      get(this.auth, 'user.profile.name.last'),
      get(this.auth, 'user.profile.email')
    ];

    const requiredFields = isBaseUser
      ? commonFields
      : [...commonFields, get(this.auth, 'password')];

    return every(requiredFields);
  }

}

export const RecoRequestAuthComponent = {
  template: require('./reco-request-auth.component.jade'),
  controller: RecoRequestAuthController,
  bindings: {
    setClient: '&',
    onAuthSubmit: '&',
    venueName: '<'
  }
};
