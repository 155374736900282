import spaceAvailability from 'common/dist/spaceAvailability';
import price from 'common/dist/price';
import get = require('lodash/get');

export class RecosService {
  constructor(private ENUMS) {
    'ngInject';
  }
  getFbMinFromLead = ({ lead, space }): number => {
    const timeSlot = this.getTimeSlot({ lead, space });
    return timeSlot ? get<number>(timeSlot, 'terms.foodBeverageMin') : null;
  }

  getRoomFee = ({ lead, space }): number => {
    const timeSlot = this.getTimeSlot({ lead, space });
    return get<number>(timeSlot, 'terms.roomFeeCents', 0);
  }

  getTimeSlot = ({ lead, space }): any => {
    const request = lead.request;
    const flexible = false;
    const timeSlotIndex = spaceAvailability.getTimeSlot(space, request.date, request.time, request.duration, flexible);
    if (timeSlotIndex !== -1) {
      return space.data.timeSlots[timeSlotIndex];
    }
  }

  getStatus = (recommendation, { considerClientResponse }) => {
    if (!recommendation.conversation && recommendation.venue.status === 'Lite')  {
      return recommendation.lastAvailability.value;
    }
    const state = get<string>(recommendation, 'conversation.requestState');
    if (!state && get(recommendation, 'createdThrough') === 'Client') {
      return `Added`;
    }

    if (!state && get(recommendation, 'createdThrough') !== 'Client') {
      return considerClientResponse && recommendation.isInterested !== 'Send Me A Proposal' ? (recommendation.isInterested || `Recommended`) : `Recommended`;
    }

    if (['INCOMPLETE'].includes(state)) {
      return `Request Initiated`;
    }

    if (['PROPOSAL'].includes(state)) {
      return `Proposal Requested`;
    }

    if (state === 'REQUEST') {
      return `Request Submitted`;
    }

    if (state === 'ACCEPTED_HOST') {
      return 'Proposal Confirmed by Venue';
    }

    if (state === 'ACCEPTED_GUEST') {
      return `Proposal Accepted by You`;
    }

    if (['COUNTEROFFER', 'PRE_DEPOSIT_ALTERATION'].includes(state)) {
      return `Pending Confirmation by Venue`;
    }

    if (state === 'ALTERATION_ACCEPTED_GUEST') {
      return `Modification Accepted by You`;
    }

    if (['POST_DEPOSIT_ALTERATION', 'ALTERATION_ACCEPTED_HOST', 'DEPOSIT', 'PAID'].includes(state)) {
      return 'Booked';
    }

    if (['CONCLUDED', 'RECONCILED', 'CLOSED'].includes(state)) {
      return 'Concluded';
    }

    if (state === 'EXPIRED') {
      return `Expired`;
    }
    if (state === 'DECLINED_HOST') {
      return 'Declined by Venue';
    }

    if (state === 'DECLINED_GUEST') {
      return `Declined by You`;
    }

    if (state === 'CANCELLED_POST_DEPOSIT_CLIENT') {
      return `Event Cancelled by You`;
    }

    if (state === 'CANCELLED_POST_DEPOSIT_VENUE') {
      return 'Event Cancelled by Venue';
    }

    if (state === 'CANCELLED_REQUEST_GUEST') {
      return 'Cancelled Proposal';
    }
  }

  getRecoStatusSubText = (reco, lead) => {
    const recoRequestState = get(reco, 'conversation.request.state');
    const isRecoProposalRequested = get(reco, 'conversation.request.isProposal');
    const recoCreatedThrough = get(reco, 'createdThrough');

    if (get(reco, 'venue.status') === 'Lite') {
      const creatorName = reco.creator || reco.createdBy;
      return `by ${creatorName}`;
    }

    if (!recoRequestState || isRecoProposalRequested) {
      const createdByUserText = recoCreatedThrough === 'Client' ? reco.creator : get(lead, 'owner.profile.name.first');
      const text = recoRequestState ? 'Recommended by' : 'by';
      return `${text} ${createdByUserText}`;
    }

    if (recoRequestState && !isRecoProposalRequested) {
      const createdByUserText = get(reco, 'creator') || get(reco, 'createdBy') || 'you';

      if (['INCOMPLETE', 'REQUEST'].includes(recoRequestState)) {
        return `by ${ createdByUserText }`;
      }

      return `Submitted by ${ createdByUserText }`;
    }
  }

  getRecoStatusTimestamp = (reco) => {
    const recoRequestState = get(reco, 'conversation.request.state');
    const isRecoProposalRequested = get(reco, 'conversation.request.isProposal');

    if (!recoRequestState || isRecoProposalRequested) {
      return reco.submittedDate;
    }

    if (recoRequestState === 'INCOMPLETE') {
      return get(reco, 'conversation.request.history[0].time');
    }

    return get(reco, 'conversation.request.history[1].time');
  }

  getFbMin = ({ recommendation, lead }): { hidden: boolean; number?: boolean; message?: any } => {
    const minSpendMessage = `Inquire to confirm`;
    const noMinSpendMessage = `No Minimum`;

    // if there is an attached request thats not in Request or Incomplete State
    if (recommendation.conversation && !['REQUEST', 'INCOMPLETE'].includes(recommendation.conversation.requestState)) {
      return { hidden: false, number: true, message: price.convertToDollarsAndRound(get(recommendation, 'conversation.request.host.foodBeverageMinCents', 0)) };
    }

    // if hidden on reco flag, don't display anything
    if (!recommendation.visibleFbMin) {
      return { hidden: true };
    // if custom fb min exists and is 0
    } else if (recommendation.customFbMin === 0) {
      return { hidden: false, number: false, message: noMinSpendMessage };
    // if it exists, display it
    } else if (recommendation.customFbMin) {
      return { hidden: false, number: true, message: recommendation.customFbMin };
    // if custom doesn't exist, check if space's flag is set
    } else if (!get(recommendation, 'space.showFbMin')) {
    // display book to reveal min spend
      return { hidden: false, number: false, message: minSpendMessage };
    } else {
      const fbMin = this.getFbMinFromLead({ space: recommendation.space, lead: lead });
      const num = !!fbMin;
      const message = fbMin || minSpendMessage;
      return { hidden: false, number: num, message };
    }
  }
}
