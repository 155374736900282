import { get, set } from 'lodash';

export default [
  'ngDialog',
  function (ngDialog) {
    return function ({ locationData }) {
      return ngDialog.open({
        template: require('./google-location.modal.jade'),
        className: 'ngdialog-theme-small micro-modal',
        overlay: true,
        plain: true,
        controllerAs: '$ctrl',
        controller: ['$scope', function ($scope) {
          this.init = () => {
            this.locationDetails = locationData;
          };
          this.init();

          this.completeAddress = (details) => {
            set(this.locationDetails, 'city', details.city);
            set(this.locationDetails, 'state', details.state);
            set(this.locationDetails, 'zipCode', details.zip);
            set(this.locationDetails, 'country', details.country);
            set(this.locationDetails, 'mapUrl', details.url);
            set(this.locationDetails, 'coordinates', [details.latitude, details.longitude]);
          };

          this.isValidLocation = () => {
            if (!get(this.locationDetails, 'address')) {
              return false;
            }
            return true;
          };

          this.handleLocationAddress = () => {
            this.errorMsg = '';
            if (!get(this.locationDetails, 'address')) {
              this.locationDetails = {};
            }
          };

          this.saveLocation = () => {
            if (!this.isValidLocation()) {
              this.errorMsg = 'Please input the address to save the location.';
              return;
            }
            $scope.closeThisDialog({ location: this.locationDetails });
          };
        }],
      }).closePromise;
    };
  },
];
