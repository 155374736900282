class PremiumPageSubscriptionCardController {
    pro: Boolean;
    premium: Boolean;
    isDowngraded: Boolean;
    premiumAccountRequested: Boolean;
    requestAccess: () => any;
    isReqForTrailPending: boolean;

    // Add $user and demoVideoModal to the constructor parameters
    constructor(
      private $user,
      private demoVideoModal,
      private $clickOk
    ) {
      'ngInject';
    }

    openDemoVideoModal = ({ demoRequest }) => {
      return this.demoVideoModal(demoRequest);
    }

    openDowngradeFreeModal = () => {
      this.$clickOk(`To downgrade to our free tier, please reach out to us at concierge@sixplus.com. We’ll notify you as soon as your request is complete!`, false, 'Request Downgrade', 'mailto:concierge@sixplus.com?subject=Request to downgrade');
    }
  }

  export const premiumPageSubscriptionCardComponent = {
    template: require('./premium-page-subscription-cards.component.jade'),
    controller: PremiumPageSubscriptionCardController,
    bindings: {
      pro: '<',
      premium: '<',
      isDowngraded: '<',
      premiumAccountRequested: '<',
      requestAccess: '&',
      isReqForTrailPending: '<'
    }
  };
